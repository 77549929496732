import { Injectable } from '@angular/core';
import { UserService } from '../data-services/user.service';
import { FilesCacheService } from './files-cache.service';
import { UtilsService } from '../utils.service';
import { AudioSpeed } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class AudioService {


  private currentAudio: HTMLAudioElement | null = null;

  playReflectionAudio() {
    const audio = new Audio('assets/sounds/reflection.mp3');
    audio.load();
    audio.play();
  }

  playAudio(audioUrl: string): HTMLAudioElement {

    if (this.currentAudio) {
      if (this.currentAudio.ontimeupdate) {
        this.currentAudio.ontimeupdate = null;
      }
      this.stopAudio();

    }

    this.currentAudio = new Audio(audioUrl);
    this.currentAudio.play()
      .catch(error => {
        console.error('Error playing audio:', error);
      });

    return this.currentAudio;
  }

  stopAudio() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
      this.currentAudio = null;
    }
  }



  private successAudio = new Audio();
  private mistakeAudio = new Audio();
  private writtngAudio = new Audio();
  private coinAudio = new Audio();

  private noValid = new Audio();

  constructor(
    private filesCacheService: FilesCacheService,
    private userService: UserService,
    private utilsService: UtilsService,
  ) {
    this.successAudio.src = 'assets/sounds/definite.mp3';
    this.successAudio.volume = 0.3;
    this.successAudio.load();

    this.mistakeAudio.src = 'assets/sounds/beyond-doubt.mp3';
    this.mistakeAudio.volume = 0.2;
    this.mistakeAudio.load();

    this.writtngAudio.src = 'assets/sounds/your-turn.mp3';
    this.writtngAudio.load();
    this.writtngAudio.volume = 0.3;

    this.noValid.src = 'assets/sounds/direct-quieter.mp3';
    this.noValid.volume = 0.3;
    this.noValid.load();

    this.coinAudio.src = 'assets/sounds/dropping-coin.mp3';
    this.coinAudio.volume = 0.3;
    this.coinAudio.load();
  }

  public playSuccess(): void {
    this.successAudio.play();
  }

  public playNoValid(): void {
    this.noValid.play();
  }

  public async playMistake() {
    // this.mistakeAudio.play();

    try {
      this.playWithSrc('assets/sounds/beyond-doubt.mp3');
    } catch (e) {
      console.log('no able to play  audio', e);
    }
  }

  public playWriting() {
    // this.playWithSrc('assets/sounds/your-turn.mp3');
    this.writtngAudio.play();
  }

  public playCoins(n: number) {
    if (n === 1) {
      this.coinAudio.play();
    } else if (n === 2) {
      this.play2Coins();
    } else if (n >= 3) {
      this.play3Coins();
    }
  }

  public async play2Coins() {
    const coinAudio1 = new Audio();

    coinAudio1.src = 'assets/sounds/dropping-coin.mp3';
    coinAudio1.volume = 0.4;
    coinAudio1.load();
    coinAudio1.playbackRate = 1.2;
    coinAudio1.play();

    await this.utilsService.sleepAsync(900);
    coinAudio1.load();
    coinAudio1.playbackRate = 1.2;
    coinAudio1.play();
  }

  public async play3Coins() {
    const coinAudio1 = new Audio();
    coinAudio1.src = 'assets/sounds/dropping-coin.mp3';
    coinAudio1.volume = 0.4;

    coinAudio1.load();
    coinAudio1.playbackRate = 1.3;
    coinAudio1.play();

    await this.utilsService.sleepAsync(850);
    coinAudio1.load();
    coinAudio1.playbackRate = 1.3;
    coinAudio1.play();

    await this.utilsService.sleepAsync(850);
    coinAudio1.load();
    coinAudio1.playbackRate = 1.3;
    coinAudio1.play();
  }

  public async playWithSrc(source: any): Promise<void> {
    // can be url string or Blob

    return new Promise<void>((resolve, reject) => {

      // check if source is instance of Blob
      if (source instanceof Blob) {
        source = URL.createObjectURL(source);
      }

      const audio = new Audio(source);
      audio.load();
      const user = this.userService.getUserSnapshot();
      audio.playbackRate = this.getSpeedRate(user.settings.audioSpeed || AudioSpeed.Regular);
      audio.play();
      audio.onended = () => {
        console.log('audio played');
        resolve();
      };
      audio.onerror = (error) => {
        reject(error);
      };
    });
  }

  getSpeedRate(speed: AudioSpeed) {
    switch (speed) {
      case AudioSpeed.VerySlow:
        return 0.65;
      case AudioSpeed.Slow:
        return 0.8;
      case AudioSpeed.Regular:
        return 1;
      case AudioSpeed.Fast:
        return 1.25;
      default:
        return 1;
    }

  }

  async playAudioWithStoragePath(path: string) {
    const srcURL = await this.filesCacheService.getURLSrcFile(path);
    this.playWithSrc(srcURL);
  }

}
