import { Component, HostListener } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

// import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';

import { MobileService } from './core/system/mobile.service';
import { AuthGuardService } from './core/auth-guard.service';

import { UserSelectors } from './core/store/user';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MessageService, PrimeTemplate } from 'primeng/api';
import { ToastService } from './core/system/toast.service';
import { filter, map } from 'rxjs';
import { UtilsDataService } from './core/data-services/utils-data.service';
import { UserService } from './core/data-services/user.service';
import { ToastModule } from 'primeng/toast';
import { ButtonDirective } from 'primeng/button';
import { GuidedTourModule } from 'ngx-guided-tour';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
  standalone: true,
  imports: [RouterOutlet, ToastModule, PrimeTemplate, ButtonDirective, GuidedTourModule],
})
export class AppComponent {
  public isLoading = this.store.select(UserSelectors.getIsLoading);

  constructor(
    private messageService: MessageService,
    private toastService: ToastService,
    private store: Store,
    public guard: AuthGuardService,
    // private messaging: AngularFireMessaging,
    private swUpdate: SwUpdate,
    private mobileService: MobileService,
    private logger: NGXLogger,
    private router: Router,
    private toastrService: ToastService,
    private utilsDataService: UtilsDataService,
    private translate: TranslateService,
    private userService: UserService,
  ) {
    // this.translate.setDefaultLang('es');

    toastService.messageService = messageService;

    // this.initGoogleTagManager();
  }

  public async ngOnInit() {
    this.updatePWA();
    this.utilsDataService.getServerStatus();

    // 1) checa configuración de usuario, 2) checa configuración de navegador, default es español
    const user = this.userService.getUserSnapshot();
    if (user?.settings?.baseLanguage) {
      this.translate.use(user.settings.baseLanguage);
    } else {
      if (navigator?.language?.includes('en')) {
        this.translate.use('en');
      } else {
        this.translate.use('es');
      }
    }

    // console.log('user', user.settings.baseLanguage);

    // todo on refresh token does not exist any more check what to do.
    // this.messaging.onTokenRefresh((token) => {
    //   console.log('Tengo un token refrescado');
    // });
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  private onBeforeInstallPrompt(event: Event) {
    this.logger.log('event install pwa app ');
    event.preventDefault();
    this.mobileService.eventInstall = event;
  }

  private updatePWA(): void {
    // TODO available estará obsoleto pronto, ver como replicar esta funcionalidad.
    // this.swUpdate.versionUpdates.subscribe((version) => {

    //   alert('Nueva versión, se aplicarán los cambios');
    //   window.location.reload();
    // });
    // First step check new versionUpdates works like bevore
    // TODO revisar que esto esta funcionando
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt: any) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })),
      )
      .subscribe((value) => {
        this.toastrService.info('Descargado...', 'Se tiene una pequeña actualización');

        setTimeout(() => {
          debugger;
          window.location.reload();
        }, 2000);
      });
  }

  private initGoogleTagManager() {
    // this.gtmService.addGtmToDom();

    this.router.events.forEach((item: any) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        // this.gtmService.pushTag(gtmTag);
      }
    });
  }

  // private requestPermission() {
  //   this.messaging.requestToken.subscribe(token => {
  //     console.log('Tengo el token del usuario ', token);
  //     console.log('aqui es donde hago el proceso de guardar el token');
  //     alert('el token es : ' + token);
  //   }, (err) => {
  //     console.error('Unable to get permission to notify.', err);
  //   });

  // }

  // private listenToNotifications() {
  //   this.messaging.messages.subscribe(message => {
  //     console.log('Guardando notificaciones', message);
  //     // Aqui es donde puedo iniciar las llamadas a los routers etc. o enviar al producto etc.
  //     // this.listenToNotifications();
  //   });
  // }

  public toSocialNetwork(social: string) {
    let url;
    if (social === 'instagram') {
      url = 'https://www.instagram.com/appingles.oficial/';
    } else if (social === 'tiktok') {
      url = 'https://www.tiktok.com/@appingles';
    } else if (social === 'youtube') {
      url = 'https://www.youtube.com/@appingles';
    }

    window.open(url, '_blank');
  }
}
