import { EnglishWord } from './../../classes';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, take, tap, filter } from 'rxjs/operators';

import { TopicService } from '../../data-services/topic.service';
import { TopicsActions, TopicsSelectors } from '.';
import { Topic } from '../../classes';

import { StoreUtilService } from './../../../core/store/store-util.service';

@Injectable()
export class TopicsEffects {
  constructor(
    private storeUtilService: StoreUtilService,
    private actions$: Actions,
    private topicService: TopicService,
  ) {}
  // Para no volver a traer los temas utiliza no se ejecuta el flujo si encuentra isLoaded en el estado
  loadTopics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TopicsActions.loadTopics),
      filter(() => !this.isLoaded),
      mergeMap(() => this.topicService.getTopics$().pipe(map((topics) => TopicsActions.loadTopicsSuccess({ topics })))),
    );
  });

  loadWordsTopic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TopicsActions.loadWordsOfTopic),
      filter((action) => this.hasPendingWords(action.topicId)), // omite si las palabras ya estan cargadas
      mergeMap((action) => {
        return this.topicService
          .getWordsOfTopic$(action.topicId)
          .pipe(map((words) => TopicsActions.updateTopicWords({ words })));
      }),
    );
  });

  loadFullTopic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TopicsActions.loadFullTopicWithId),
      filter((action) => this.hasPendingWords(action.topicId)),
      mergeMap((action) => {
        return this.topicService.getFullTopic$(action.topicId).pipe(
          map((topic) => {
            return TopicsActions.upsertTopic({ topic: topic });
          }),
        );
      }),
    );
  });

  private hasPendingWords(id: string): boolean {
    // TODO: revisar  que pasa cuando el tema no tiene palabras esto siempore regresará true y hará una llamada.
    const topic = this.storeUtilService.getSnapshot<Topic>(TopicsSelectors.getTopicById(id));
    const existWords = !!topic && !!topic.words;
    return !existWords;
  }

  private get isLoaded() {
    const isLoaded = this.storeUtilService.getSnapshot<boolean>(TopicsSelectors.getIsLoaded);
    return isLoaded;
  }
}
