import { Injectable } from '@angular/core';
import { EnglishVerb, EnglishWord, GeneralWord, PromoCode } from '../classes';
import { AdminApi, WordType } from '../enums';
import { HttpService } from '../system/http.service';
import { UserService } from './user.service';

export interface PromotedData {
  current: GeneralWord;
  promoted: Array<GeneralWord>;
}

export interface DiccionaryData {
  [key: string]: PromotedData;
}

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private httpService: HttpService, private userService: UserService) {}

  public async getWordsPromoted() {
    return this.httpService.getDataFromService<{ [key: string]: any }>(AdminApi.PromotedWords);
  }

  public async getVerbsPromoted() {
    return this.httpService.getDataFromService<{ [key: string]: any }>(AdminApi.PromotedVerbs);
  }

  public async mergePromotedWord(payLoad: any) {
    return this.httpService.postDataToService(AdminApi.MergeWord, payLoad);
  }

  public async deletePromotion(id: string, wordType: WordType) {
    const path = `${AdminApi.DeletePromotion}/${wordType}/${id}`;
    return this.httpService.deleteDataFromService(path);
  }

  public async addTagToWords(tag: string, wordsToTag: Array<string>) {
    const payload = { tag, words: wordsToTag };
    return this.httpService.postDataToService(AdminApi.TagWords, payload);
  }

  public async getFullWord(word: string) {
    const langString = this.userService.getUserLangOptions();

    return this.httpService.getDataFromService<EnglishWord>(`${AdminApi.Word}/${word}?${langString}`);
  }

  public async deleteWord(word: string) {
    return this.httpService.deleteDataFromService(`${AdminApi.Word}/${word}`);
  }

  public async getFullVerb(verb: string) {
    return this.httpService.getDataFromService<EnglishVerb>(`${AdminApi.Verb}/${verb}`);
  }

  public async postVerb(verb: EnglishVerb) {
    return this.httpService.postDataToService<EnglishVerb>(`${AdminApi.UpdateKnowledge}/verb`, verb);
  }

  public async postWord(word: EnglishWord) {
    debugger;
    return this.httpService.postDataToService<EnglishVerb>(`${AdminApi.UpdateKnowledge}`, word);
  }

  public async postPromoCode(code: PromoCode | any) {
    return this.httpService.postDataToService<any>(`${AdminApi.PromoCode}`, code);
  }
}
