export interface ImageSettings {
  path: string,
  fileName: string,
  resizeToWidth: number
}


export interface ImageMultipleCrops {
  file: File;
  defaultImageBlob?: Blob;
  imagesBlobs: { [resolution: number]: Blob };
  settings: { renameFile: string, width?: number };
}


export enum AspectType {
  Square = 'square',
  Rectangle = 'rectangle',
  Vertical_9_16 = 'vertical_9_16',
}

// Creo que seria bueno refactorizar para solo enviar aspect ratio asi
export enum AspectRatio2 {
  // Vertical
  "9:16" = 16 / 9,
  "3:5" = 3 / 5,
  "5:8" = 5 / 8,
  "2:3" = 2 / 3,
  "1:1" = 1 / 1,
}



export enum ResolutionType {
  Small = 200,
  Medium = 400,
  MediumLarge = 800,
  Large = 1200,
  VeryLarge = 1600,
}

export const AspectRatio = {
  [AspectType.Square]: 1 / 1,
  [AspectType.Rectangle]: 16 / 9,
  [AspectType.Vertical_9_16]: 9 / 16,
};

export interface ImgStorageData {
  name?: string;
  bucket: string;
  url: string;
  path: string; // path where the file is in the storage
  fullPath: string; // path + name
  resolution: string;
  resolutions: any;
}

export interface BaseStorage {
  bucket: string;
  url: string;
  path: string; // path where the file is in the storage
}

