import { Routes } from '@angular/router';

import { AuthGuardService } from './core/auth-guard.service';
import { IsAuthGuardService } from './core/is-auth-guard.service';
import { IsMobileGuardService } from './core/is-mobile-guard.service';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./information/information-routing').then((m) => m.RoutesInfo),
    canActivate: [IsMobileGuardService],
  },
  {
    path: 'm',
    loadComponent: () => import('./layout-ionic/layout-ionic.component').then((m) => m.IonicLayoutComponent),
    loadChildren: () => import('./pages/page.routing').then((m) => m.RoutesPage),
    canActivate: [AuthGuardService],
  },
  {
    path: 'm/auth',
    loadComponent: () => import('./layout-ionic/ionic-page-wraper/ionic-page-wraper.component').then((m) => m.IonicPageWraperComponent),
    loadChildren: () => import('./auth/auth-routing').then((m) => m.RoutesAuth),
  },
  {
    path: 'm/welcome',
    loadComponent: () => import('./information/welcome/welcome.page').then((m) => m.WelcomePage),
  },
  {
    path: 'm',
    loadComponent: () => import('./layout-ionic/ionic-page-wraper/ionic-page-wraper.component').then((m) => m.IonicPageWraperComponent),
    loadChildren: () => import('./information/information-routing').then((m) => m.RoutesInfo),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth-routing').then((m) => m.RoutesAuth),
  },

  {
    path: '',
    loadChildren: () => import('./layout/layout-routing').then((m) => m.RoutesLayout),
    canActivate: [AuthGuardService],
  },
];
