import { createReducer, on } from '@ngrx/store';

import { UserActions, UserState } from '.';
import { hydrateStorage, getStorageRehydration, StorageStateEnum } from '../app.state';
import { User } from '../../classes';

const userStorage = getStorageRehydration<User>(StorageStateEnum.User);

export const userInitialState: UserState = {
  user: userStorage,
  isLoading: false,
  isLoaded: false,
  isRehydrated: !!userStorage,
  error: null,
};

export const userReducer = createReducer(
  userInitialState,

  on(UserActions.resetUserInitialState, () => {
    return { ...userInitialState, user: null, isLoaded: false, isRehydrated: false };
  }),

  on(UserActions.setIsLoaded, (state, action) => ({ ...state, isLoaded: action.isLoaded })),

  on(UserActions.setIsLoading, (state, action) => ({ ...state, isLoading: action.isloading })),

  on(UserActions.loadUserSuccess, (state, action): UserState => {
    return { user: action.user, isLoading: false, isLoaded: true, error: '', isRehydrated: false };
  }),

  on(UserActions.setUser, (_, action): UserState => {
    hydrateStorage(StorageStateEnum.User, action.user);
    return { user: action.user, isLoading: false, isLoaded: true, error: '', isRehydrated: false };
  }),

  on(UserActions.updateUserSubscription, (state, action): UserState => {
    const user = { ...state.user, subscription: action.subscription };
    hydrateStorage(StorageStateEnum.User, user);

    return { ...state, user };
  }),

  on(UserActions.updateUserProperties, (state, action): UserState => {
    // puede modificar cualquier propiedad del objeto usuario
    const user = { ...state.user };

    Object.keys(action.user).forEach((key) => {
      user[key] = action.user[key];
    });
    hydrateStorage(StorageStateEnum.User, user);

    return { ...state, user };
  }),

  on(UserActions.updateSettings, (state, action): UserState => {
    const user = { ...state.user, settings: action.settings };
    hydrateStorage(StorageStateEnum.User, user);
    return { ...state, user };
  }),

  on(UserActions.updateStep, (state, action): UserState => {
    const frontendSteps = { ...state.user.frontendSteps, [action.step]: action.value };
    const user = { ...state.user, frontendSteps };
    hydrateStorage(StorageStateEnum.User, user);

    return { ...state, user };
  }),

  on(UserActions.addStatPoints, (state, action): UserState => {
    const points = state.user.stats.points + action.points;
    const stats = { ...state.user.stats, points };
    const user = { ...state.user, stats };
    hydrateStorage(StorageStateEnum.User, user);
    return { ...state, user };
  }),
);
