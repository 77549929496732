<p-dialog header="Selecciona y recorta la imagen" [(visible)]="isCropperVisible" [modal]="true"
    [style]="{ width: '55vw' }" [draggable]="false" [resizable]="false">
    <app-cropper (imageUploaded)="onUploadedImage($event)" ratioType="vertical_9_16"
        [imageSettings]="{ path: 'words/' + word + '/images' }"></app-cropper>
</p-dialog>


ID: {{ form.controls.id.value }}
<p-button (click)="removeLearningExample()" label="Eliminar" icon="pi pi-times" [text]="true" severity="danger">
</p-button>

<p-button label="Agregar/Cambiar imagen" (click)="showImageCropper()" [text]="true" [raised]="true"></p-button>

<div *ngIf="form.get('image').value?.url">
    <img width="300px" [src]="form.get('image').value.url" alt="" />
</div>



<div class="inputs-container">
    <div class="flex">
        <input class="w-full" type="text" pInputText [formControl]="form.controls.example"
            placeholder="Escribe oración ejemplo (Ingles)" />

        <p-button *ngIf="id && !form.controls.audio.value" label="Generar" [text]="true"
            (click)="generateAudio()"></p-button>

        <p-button *ngIf="form.controls.audio.value" (click)="playAudio(form.controls.audio.value)" icon="pi pi-play"
            [text]="true"></p-button>
    </div>

    <input class="w-full" type="text" pInputText [formControl]="form.get('meaning')"
        placeholder="Significado o Relevacia de la palabra " />


    <input class="w-full" type="text" pInputText [formControl]="form.get('scenario')" placeholder="Scenario" />



    <input class="w-full" type="text" pInputText [formControl]="form.get('attribution')"
        placeholder="Atribuciones al autor" />

    <h6>Traducciones Español</h6>

    <input class="w-full" type="text" pInputText [formControl]="form.get('es').get('translation')"
        placeholder="Traducción" />

    <input class="w-full" type="text" pInputText [formControl]="form.get('es').get('meaningTranslation')"
        placeholder="Escribe explicación (español)" />

    <h6>Traducciones Inglés</h6>

    <input class="w-full" type="text" pInputText [formControl]="form.get('en').get('translation')"
        placeholder="Traducción ingles" />

    <input class="w-full" type="text" pInputText [formControl]="form.get('en').get('meaningTranslation')"
        placeholder="Significado translation" />



    <p-checkbox [formControl]="form.get('isPublic')" [binary]="true" inputId="binary"></p-checkbox> Mostrar al público?

    <div class="grid">
        <div class="sm:col-6">
            <hr>
            <h4>Revisar voice type descomentar hay error</h4>

            <!-- <p-dropdown [options]="voiceOptions" [formControl]="form.get('voiceType')" optionLabel="name"
                optionValue="id" [showClear]="true" placeholder="Voz" formControlName="voice">

                <ng-template let-voice pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div>{{ voice.provider }}</div>
                        -
                        <div>{{ voice.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>

            <p-button *ngIf="form.controls.audio.value" (click)="playVoiceExample(form.controls.voiceType.value)"
                icon="pi pi-play" [text]="true"></p-button> -->

        </div>
    </div>
</div>



<p-button [loading]="isLoading" *ngIf="form.controls.example.dirty" label="AutoCompletar con AI"
    (click)="autoCompleteExample()" [raised]="true" severity="help" size="small"></p-button>

<p-button [loading]="isLoading" *ngIf="form.dirty" [label]=" id? 'Guardar Cambios' : 'Crear Ejemplo'"
    (click)="saveLearningExample()" [raised]="true" severity="help" size="small"></p-button>