import { WordActions } from '../store/words';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HttpService } from '../system/http.service';
import { DeletedData, LangWord, GeneralWord, ProgressResultsDto, ProgressWordEvaluation, RandomFilter, WordFilterCategory } from '../classes';
import { WordFormMode, WordWebApi } from '../enums';
import { addWords, createWordSuccess } from '../store/words/words.actions';
import { StoreUtilService } from '../store/store-util.service';
import { WordSelectors } from '../store/words';
import { WordFormComponent } from 'src/app/pages/my-words/word-form/word-form.component';
import { UserActions } from '../store/user';
import { ConfirmService } from 'src/app/shared/confirm/confirm.service';
import { AudioService } from '../system/audio.service';
import { ToastService } from '../system/toast.service';
import { DialogService } from 'primeng/dynamicdialog';
import { UserService } from './user.service';
import { StorageStateEnum, hydrateStorage } from '../store/app.state';
import { AiService } from './ai.service';

@Injectable({
  providedIn: 'root',
})
export class WordService {
  constructor(
    private dialogService: DialogService,
    private storeUtilService: StoreUtilService,
    private store: Store,
    private httpService: HttpService,
    private toastrService: ToastService,
    private confirmService: ConfirmService,
    private audioService: AudioService,
    private aiService: AiService,
    private userService: UserService,
  ) {}

  public getWords$(): Observable<Array<LangWord>> {
    const langString = this.userService.getUserLangOptions();
    return this.httpService.getObservable<Array<LangWord>>(`${WordWebApi.GetUserWord}?${langString}`, 'nodejs');
  }

  public resetWordsState(): void {
    this.store.dispatch(WordActions.resetInitialState());
    hydrateStorage(StorageStateEnum.Words, null);
  }

  public dispatchLoadWords(): void {
    this.store.dispatch(WordActions.loadWords());
  }

  public getRandomWords(
    n: number,
    by = WordFilterCategory.UseFrequency,
    value: any | any[],
    lang: { base: string; target: string } = null,
  ): Promise<Array<LangWord>> {
    const filter: RandomFilter = { nWords: n, by: by, values: value };
    const langString = lang ? `target=${lang.target}&base=${lang.base}` : this.userService.getUserLangOptions();
    return this.httpService.postDataToService(`${WordWebApi.RandomWord}?${langString}`, filter);
  }

  public async removeFromUserList(word: string): Promise<DeletedData> {
    const langString = this.userService.getUserLangOptions();

    const response = await this.httpService.deleteDataFromService(`${WordWebApi.DeleteWord}/${word}?${langString}`);
    this.store.dispatch(WordActions.deleteWordSuccess({ word }));
    return response;
  }

  public async editWord(wordModified: any): Promise<LangWord> {
    const wordEdited: LangWord = await this.httpService.postDataToService(wordModified, WordWebApi.PostWord);
    this.store.dispatch(WordActions.updateWordSuccess({ word: wordEdited }));
    return wordEdited;
  }

  public async saveWordToVocabulary(word: string): Promise<LangWord> {
    if (this.isWordInUserList(word)) {
      this.toastrService.warn(`Ya tienes registrada la palabra '${word}' `, 'Recodatorio');
      return null;
    }

    try {
      const langString = this.userService.getUserLangOptions();
      const wordSaved: LangWord = await this.httpService.postDataToService(`${WordWebApi.SaveWordWithString}?${langString}`, { word });
      this.store.dispatch(createWordSuccess({ word: wordSaved }));
      this.toastrService.success(`'${word}' significa '${wordSaved.translation}' `, `Palabra '${wordSaved.word}' Guardada`);
      return wordSaved;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public async saveMultipleWordsToVocabulary(words: string[]): Promise<any> {
    // TODO: este metodo no valida, que las palabras no las tenga repetidas.

    try {
      const langString = this.userService.getUserLangOptions();
      const wordSaved: LangWord[] = await this.httpService.postDataToService(`${WordWebApi.SaveMultipleWords}?${langString}`, {
        words: words,
      });

      this.store.dispatch(addWords({ word: wordSaved }));
      this.toastrService.success(`Se guardaron las palabras '${words}' `, `Palabras Guardadas`);
      return wordSaved;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public promoteWord$(id: string): Observable<LangWord> {
    return this.httpService.getObservable<LangWord>(`${WordWebApi.PromoteWord}/${id}`);
  }

  public async promoteWord(word: LangWord): Promise<LangWord> {
    return await this.httpService.postDataToService(WordWebApi.PromoteWord, word);
  }

  public dispatchNewWord(word: LangWord): void {
    this.store.dispatch(createWordSuccess({ word }));
  }

  public async saveProgressWord(
    progressWords: Array<ProgressWordEvaluation>,
    callbackFn: () => void = null,
  ): Promise<Array<ProgressWordEvaluation>> {
    const langString = this.userService.getUserLangOptions();
    const responseProgress = await this.httpService.postDataToService<ProgressResultsDto>(
      `${WordWebApi.SaveProgress}?${langString}`,
      progressWords,
    );

    this.store.dispatch(WordActions.updateProgressWord({ progress: responseProgress.progress }));

    if (responseProgress.points > 0) {
      const points = this.userService.getUserSnapshot().stats.points;

      const rightMostDigit = points % 10;
      const n_coints = Math.floor((rightMostDigit + responseProgress.points) / 10);
      console.log('n_coints', n_coints, 'points', responseProgress.points, 'right points', rightMostDigit);
      this.audioService.playCoins(n_coints);

      this.store.dispatch(UserActions.addStatPoints({ points: responseProgress.points }));
    }

    const data = responseProgress.progress.filter((word) => word.justCompleted);
    if (data.length > 0) {
      let title = '';
      let message = '';

      if (data.length === 1) {
        title = `Felicidades completaste el progreso para la palabra ${data[0].word}.`;
        message = `¿Quieres eliminar ${data[0].word} de tu lista de aprendizaje ?`;
      } else {
        title = `Felicidades completaste el progreso para las palabras ${data.map((item) => item.word).join(', ')}.`;
        message = `¿Quieres eliminar ${data.map((item) => item.word).join(', ')} de tu lista de aprendizaje ?`;
      }

      const ref = this.confirmService.openConfirm(title, message, 'Eliminar');

      ref.subscribe(async (result) => {
        console.log('subs Eliminar palabra', result);

        if (result) {
          for (const word of data) {
            await this.removeFromUserList(word.word);
            if (callbackFn) {
              callbackFn();
            }
          }
        }
      });
    }

    return responseProgress.progress;
  }

  public async updateWordProperty(word: string, property: string, value: any): Promise<void> {
    this.store.dispatch(WordActions.updateWordProperty({ word, property, value }));
  }

  public getWordsSnapshot(): ReadonlyArray<LangWord> {
    return this.storeUtilService.getSnapshot<ReadonlyArray<LangWord>>(WordSelectors.getWords);
  }

  public isWordInUserList(word: string): boolean {
    const words = this.getWordsSnapshot() || [];
    const isFound = !!words.find((item) => item.word === word);
    return !!isFound;
  }

  public async getSingleWord(word: string): Promise<LangWord> {
    const langString = this.userService.getUserLangOptions();

    return this.httpService.getDataFromService(`${WordWebApi.GetWord}/${word}?${langString}`);
  }

  public openWordFormDialog(word: LangWord, topicId = null, mode: WordFormMode = null): void {
    const context = { englishWord: word, topicId, mode };
    console.log(context);
    const dialogRef = this.dialogService.open(WordFormComponent, {
      data: context,
    });

    dialogRef.onClose.subscribe((_) => {});
  }

  public async playWordAudioOrGenerate(word: GeneralWord) {
    if (word?.audios && word.audios.length > 0) {
      this.audioService.playWithSrc(word.audios[0].url);
    } else {
      try {
        const audio = await this.aiService.generateWordAudio(word.word);
        this.updateWordProperty(word.word, 'audios', [audio]);
        this.audioService.playWithSrc(audio.url);
        try {
          word.audios = [audio];
        } catch (error) {
          console.error('Audio is read only enviar copia {...}', error);
        }
      } catch (error) {
        return null;
      }
    }
  }
}
