import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  version: '0.1.0',
  envName: 'PRO',
  production: true,
  backUrl: 'https://app-dao63zvdwa-uc.a.run.app',
  backApisUrl: 'https://apis-dao63zvdwa-uc.a.run.app',
  clientId: '567696811537-o9kifuiqvrbt0omj0j65ek9cth1kmujt.apps.googleusercontent.com',

  firebase: {
    apiKey: 'AIzaSyAghv8OBPBJGayE8DpkdwHFkG03H9HlHes',
    authDomain: 'appingles-pro.firebaseapp.com',
    databaseURL: 'https://appingles-pro.firebaseio.com',
    projectId: 'appingles-pro',
    storageBucket: 'appingles-pro.appspot.com',
    messagingSenderId: '567696811537',
    appId: '1:567696811537:web:085f5912ef28ef71bbb3b0',
  },
  logLevel: NgxLoggerLevel.INFO,
  // googleAnalytics: "G-88TL1EH47G", // TODO poner etiqueta de prod
  gtm: 'GTM-KKW6C7Z',
};
