import { AudioSpeed, PlanType, TextEngines } from './enums';

export const LangCodeDescription = {
  es: 'Spanish',
  en: 'English',
  it: 'Italian',
  pt: 'Portuguese',
  fr: 'French',
};

export const LangCodeDescriptionEs = {
  es: 'Español',
  en: 'Inglés',
  it: 'Italiano',
  pt: 'Portugués',
  fr: 'Frances',
};

export const GenderOptions = [
  { name: 'Hombre', code: 'male' },
  { name: 'Female', code: 'female' },
  { name: 'Otro', code: 'other' },
];

export const AudioSpeedOptions = [
  { name: 'Muy Lento', code: AudioSpeed.VerySlow },
  { name: 'Lento', code: AudioSpeed.Slow },
  { name: 'Normal', code: AudioSpeed.Regular },
  { name: 'Rápida', code: AudioSpeed.Fast },
];

export const TargeLanguagesOptions = [
  { name: 'Español 🇪🇦 🇲🇽 ', code: 'es' },
  { name: 'Inglés 🇺🇸 🇬🇧 ', code: 'en' },
  { name: 'Portugués 🇧🇷 🇵🇹', code: 'pt' },
  { name: 'Italiano 🇮🇹', code: 'it' },
  { name: 'Frances 🇫🇷', code: 'fr' },
];

export const LanguageLevelOptions = [
  { name: 'Principiante', code: '1' },
  { name: 'Principiante Intermedio', code: '2' },
  { name: 'Intermedio', code: '3' },
  { name: 'Intermedio Avanzado', code: '4' },
  { name: 'Avanzado', code: '5' },
];

export const BaseLanguagesOptions = [
  { name: 'Español 🇪🇦 🇲🇽 ', code: 'es' },
  { name: 'Inglés 🇺🇸 🇬🇧 ', code: 'en' },
];

export const VoiceOptions = [
  {
    provider: 'openai',
    name: 'Fable',
    id: 'fable',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/openai_fable.mp3',
  },
  {
    provider: 'openai',
    name: 'Onyx',
    id: 'onyx',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/open_onyx.mp3',
  },
  {
    provider: 'openai',
    name: 'Alloy',
    id: 'alloy',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/openai_alloy.mp3',
  },
  {
    provider: 'openai',
    name: 'Nova',
    id: 'nova',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/openai_nova.mp3',
  },
  {
    provider: 'openai',
    name: 'Shimmer',
    id: 'shimmer',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/openai_shimmer.mp3',
  },
  {
    provider: 'google',
    name: 'Woman Studio O',
    id: 'en-US-Studio-O',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_Studio-O.mp3',
  },
  {
    provider: 'google',
    name: 'Man Studio Q',
    id: 'en-US-Studio-Q',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_Studio-Q.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Charlotte',
    id: 'XB0fDUnXU5powFXDhCwa',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Charlotte.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Grace',
    id: 'oWAxZDx7w5VEj9dCyTzz',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Grace.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Nicole',
    id: 'piTKgcLEGmPE4e6mEKli',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Nicole.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Emily',
    id: 'LcfcDJNUP1GQjkzn1xUU',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Emily.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Thomas',
    id: 'GBv7mTt0atIp3Br8iCZE',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Thomas.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Chris',
    id: 'iP95p4xoKVk53GoZ742B',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Chris.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Harry',
    id: 'SOYHLrjzK2X1ezoPC6cr',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Harry.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Gigi',
    id: 'jBpfuIE2acCO8z3wKNLl',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Gigi.mp3',
  },
];

export const VoiceTTSOptions = [
  // Auto
  { provider: 'google', name: 'Automatic', id: 'auto' },
  // English
  { provider: 'google', name: 'Hombre Journey', id: 'en-US-Journey-D', exampleUrl: '', gender: 'male', lang: 'en-US' },
  { provider: 'google', name: 'Hombre Noticias', id: 'en-US-News-N', exampleUrl: '', gender: 'male', lang: 'en-US' },
  { provider: 'google', name: 'Mujer Journey', id: 'en-US-Journey-F', exampleUrl: '', gender: 'female', lang: 'en-US' },
  { provider: 'google', name: 'Mujer Noticias', id: 'en-US-News-L', exampleUrl: '', gender: 'female', lang: 'en-US' },
  { provider: 'google', name: 'Woman en-US-Neural2-F', id: 'en-US-Neural2-F', gender: 'female', lang: 'en-US', exampleUrl: '' },

  // Spanish
  { provider: 'google', name: 'Woman es-US-Neural2-A', id: 'es-US-Neural2-A', gender: 'female', lang: 'es-US' },
  { provider: 'google', name: 'Man es-US-Neural2-B', id: 'es-US-Neural2-B', gender: 'male', lang: 'es-US' },
  { provider: 'google', name: 'Man es-US-Neural2-C', id: 'es-US-Neural2-C', gender: 'male', lang: 'es-US' },
  // Italian
  { provider: 'google', name: 'Woman it-IT-Neural2-A', id: 'it-IT-Neural2-A', gender: 'female', lang: 'it-IT' },
  { provider: 'google', name: 'Man it-IT-Neural2-C', id: 'it-IT-Neural2-C', gender: 'male', lang: 'it-IT' },
  // Portuguese
  { provider: 'google', name: 'Woman pt-BR-Neural2-C', id: 'pt-BR-Neural2-C', gender: 'female', lang: 'pt-BR' },
  { provider: 'google', name: 'Man pt-BR-Neural2-B', id: 'pt-BR-Neural2-B', gender: 'male', lang: 'pt-BR' },
  // Frances
  { provider: 'google', name: 'Man fr-FR-Neural2-B', id: 'fr-FR-Neural2-B', gender: 'male', lang: 'fr-FR' },
  { provider: 'google', name: 'Woman fr-FR-Neural2-E', id: 'fr-FR-Neural2-E', gender: 'female', lang: 'fr-FR' },
];

export const TextEngineOptions = [
  { name: 'Text Plano', code: TextEngines.Plantext },
  { name: 'MarkDown Multi Messages', code: TextEngines.MarkdownMultiMessages },
  { name: 'Markdown SSML 1 Msn Many Voices', code: TextEngines.MarkdownSSML },
];

type MasterRulesType = { max_words: number | null; max_verbs: number | null; max_conversations: number; max_le_requests: number };

export const MasterRules: Record<string, MasterRulesType> = {
  [PlanType.Basic]: {
    max_words: 40,
    max_verbs: 20,
    max_conversations: 1, // cambiar a 1
    max_le_requests: 35,
  },
  [PlanType.Follower]: {
    max_words: null,
    max_verbs: null,
    max_conversations: 5,
    max_le_requests: 70,
  },
  [PlanType.Beta]: {
    max_words: null,
    max_verbs: null,
    max_conversations: 50,
    max_le_requests: 70,
  },
  //     # not used yet
  [PlanType.Premium]: {
    max_words: null,
    max_verbs: null,
    max_conversations: 100,
    max_le_requests: 200,
  },
};
