import { Injectable } from '@angular/core';
import { ConfirmComponent } from './confirm.component';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  constructor(public pdialogService: DialogService) { }

  openConfirm(title: string, message: string, acceptText: string) {
    const dialogRef = this.pdialogService.open(ConfirmComponent, {
      data: {
        message: message,
        title: title,
        acceptText
      },
    });

    return dialogRef.onClose;
  }
}
