<h3>Formulario palabra</h3>
<span *ngIf="loadingWord"> -> Buscando palabra...</span>

<div class="static">
  <form [formGroup]="formGroup">
    <div class="grid">
      <div class="sm:col-6">
        <div class="field">
          <label class="label">Palabra (English): </label>
          <input pInputText formControlName="word" type="text" class="w-full" placeholder="Small" />
        </div>
      </div>
      <div class="sm:col-6">
        <div class="field">
          <label class="label">Traducción (Spanish)</label>
          <input pInputText formControlName="translation" type="text" placeholder="Traducción" class="w-full" />
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-6">
        <div class="form-group">
          <label class="label">Tipo de palabra</label>
          <br />

          <p-dropdown
            formControlName="type"
            [options]="wordTypeOptions"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecciona"></p-dropdown>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label class="label">Frecuencia</label>
          <br />
          <!-- TODO add frecuency -->

          <p-dropdown
            formControlName="useFrequency"
            [options]="useFrequencyOptions"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecciona"></p-dropdown>
        </div>
      </div>
    </div>

    <!-- Esto me puede ayudar al usuario pero no se debe mostrar en otro modo.  -->

    <!-- <div class="grid">
      <div class="col-12">
        <div class="field">
          <label class="label">Progreso</label>
          <br />

          <p-dropdown
            formControlName="score"
            [options]="scoreOptions"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecciona"
          ></p-dropdown>
        </div>
      </div>
    </div> -->

    <!-- <div class="grid">
      <div class="col-12">
        <div class="form-group">
          <label class="label">Notas</label>
          <br />
          <textarea
            cols="30"
            formControlName="spanishExplanation"
            type="text"
            pInputTextarea
            placeholder="Algo que te ayude a recordar cómo o cuándo  usar la palabra"
          ></textarea>
        </div>
      </div>
    </div> -->

    <div class="form-group" formArrayName="definitions">
      <label class="label">Definiciones</label>
      <div *ngFor="let item of definitionsFormArray.controls; let i = index">
        <div class="form-input">
          <input class="full-w" pInputText type="text" [formControlName]="i" placeholder="Escribe definición..." />

          <p-button (click)="deleteFormArrayByIndex('definitions', i)" icon="pi pi-times" [text]="true" severity="danger"></p-button>
        </div>
      </div>

      <div class="grid">
        <div class="col-6">
          <p-button
            class="mar-top"
            (click)="pushControlToFormArray('definitions')"
            label="Agregar definición"
            [raised]="true"
            severity="help"
            size="small"></p-button>
        </div>
        <div class="col-6"></div>
      </div>
    </div>

    <div class="form-group" formArrayName="examples">
      <label class="label">Ejemplos</label>
      <div *ngFor="let item of formGroup.controls.examples.controls; let i = index">
        <div class="form-input">
          <input class="full-w" pInputText type="text" [formControlName]="i" placeholder="Escribe definición..." />

          <p-button (click)="deleteFormArrayByIndex('examples', i)" icon="pi pi-times" [text]="true" severity="danger"></p-button>
        </div>
      </div>

      <div class="grid">
        <div class="col-6">
          <p-button
            class="mar-top"
            (click)="pushControlToFormArray('examples')"
            label="Agregar ejemplo"
            [raised]="true"
            severity="help"
            size="small"></p-button>
        </div>
        <div class="col-6"></div>
      </div>
    </div>

    <div class="form-group" formArrayName="synonyms">
      <label class="label">Sinónimos</label>
      <div *ngFor="let item of synonymsFormArray.controls; let i = index">
        <div class="form-input">
          <input pInputText type="text" [formControlName]="i" placeholder="Escribe definición..." />

          <p-button (click)="deleteFormArrayByIndex('synonyms', i)" icon="pi pi-times" [text]="true" severity="danger"></p-button>
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <p-button
            class="mar-top"
            (click)="pushControlToFormArray('synonyms')"
            label="Agregar sinónimo"
            [raised]="true"
            severity="help"
            size="small"></p-button>
        </div>
      </div>
    </div>

    <div class="form-group" formArrayName="otherTranslations">
      <label class="label">Otras traducciones al español</label>
      <div *ngFor="let item of otherTranslationsFormArray.controls; let i = index">
        <div class="form-input">
          <input pInputText type="text" [formControlName]="i" placeholder="Escribe sinónimo..." />

          <p-button (click)="deleteFormArrayByIndex('otherTranslations', i)" icon="pi pi-times" [text]="true" severity="danger"></p-button>
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <p-button
            class="mar-top"
            (click)="pushControlToFormArray('otherTranslations')"
            label="Agregar otras traducciones"
            [raised]="true"
            severity="help"
            size="small"></p-button>
        </div>
      </div>
    </div>

    <div class="align-button">
      <p-button (click)="save()" [disabled]="formGroup.pristine" [outlined]="true"> {{ buttonText }}</p-button>
    </div>

    <hr />

    <h2>Aprendizaje</h2>
    <h5>Ejemplos de aprendizaje</h5>

    <div class="grid">
      <div class="col-6">
        <p-button (click)="addLearningExampleForm()" label="Agregar ejemplo" [outlined]="true"></p-button>
        <p-button (click)="generateExample()" label="Generar ejemplo AI" [outlined]="true"></p-button>
      </div>
      <div class="col-6"></div>
    </div>
  </form>

  <!-- ____________________________ -->

  <div *ngFor="let form of formGroup.controls.learningExamples.controls; let i = index">
    <hr />

    <app-learning-example-form
      [form]="form"
      [word]="langWord.word"
      (removed)="deleteFormArrayByIndex('learningExamples', i)"></app-learning-example-form>
  </div>

  <!-- ____________________________ -->
</div>
