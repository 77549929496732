import { enableProdMode, isDevMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { DialogService } from 'primeng/dynamicdialog';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { InterceptorTokenService } from './app/core/interceptor-token.service';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { GuidedTourService, GuidedTourModule } from 'ngx-guided-tour';
import { provideAuth, getAuth, initializeAuth, indexedDBLocalPersistence } from '@angular/fire/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { provideLottieOptions } from 'ngx-lottie';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { routes } from './app/app-routing';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LoggerModule } from 'ngx-logger';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { wordsReducer } from './app/core/store/words/words.reducer';
import { verbsReducer } from './app/core/store/verbs/verbs.reducer';
import { userReducer } from './app/core/store/user/user.reducer';
import { topicsReducer } from './app/core/store/topics/topics.reducer';
import { phrasesReducer } from './app/core/store/phrases/phrases.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { WordsEffects } from './app/core/store/words/words.effects';
import { VerbsEffects } from './app/core/store/verbs/verbs.effects';
import { UserEffects } from './app/core/store/user/user.effects';
import { TopicsEffects } from './app/core/store/topics/topics.effects';
import { PhrasesEffects } from './app/core/store/phrases/phrases.effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app/app.component';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { AppIonicComponent } from './app/app-ionic.component';
import { Capacitor } from '@capacitor/core';

if (environment.production) {
  enableProdMode();
}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    importProvidersFrom(
      BrowserModule,
      LoggerModule.forRoot({ level: environment.logLevel }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      // Others
      StoreModule.forRoot({
        words: wordsReducer,
        verbs: verbsReducer,
        user: userReducer,
        topics: topicsReducer,
        phrases: phrasesReducer,
      }),
      StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
      EffectsModule.forRoot([WordsEffects, VerbsEffects, UserEffects, TopicsEffects, PhrasesEffects]),
      GuidedTourModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        // Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
    ),
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorTokenService, multi: true },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    GuidedTourService,
    // Firebase

    provideFirebaseApp(() => initializeApp(environment.firebase)),

    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),

    provideStorage(() => getStorage()),
    provideHttpClient(withInterceptorsFromDi()),
    provideLottieOptions({
      player: () => import('lottie-web'), // check if i can move this later
    }),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
