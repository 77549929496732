<div>
  path: {{storagePath}}
</div>

<div class="options">
  <div *ngIf="!isUploaded">
    <input type="file" (change)="fileChangeEvent($event)" />
    <em *ngIf="!fileMetadata">Carga una imagen para comenzar</em>
  </div>


  <span *ngIf="fileMetadata">
    <span style="margin: 1px 20px;">
      tipo: {{fileMetadata.type}}
    </span>

    <span style="margin: 1px 20px;">
      tamaño {{fileMetadata.size}}
    </span>
    <br>
    <input [disabled]="imageSettings?.fileName" style="margin: 1px 20px; width: 400px;" [(ngModel)]="renameFile"
      type="text" placeholder="Rename File" (ngModelChange)="reloadPath()">
    <button class="btn-crop" (click)="simpleCropAndUpload()"> Recortar y Subir </button>
  </span>
</div>


<image-cropper *ngIf="!isUploaded" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
  [aspectRatio]="aspectRatio" format="webp" [resizeToWidth]="450" (imageCropped)="onInnerImageCropped($event)"
  (loadImageFailed)="loadImageFailed()" (imageLoaded)="imageLoaded($event)" [autoCrop]="false"></image-cropper>

<button *ngIf="croppedImage && !isUploaded" [disabled]='isLoading' nbButton status="info" (click)="cropImage()">
  upload </button>