import { Injectable } from '@angular/core';
import { HttpService } from '../system/http.service';
import { AiService } from './ai.service';
import { LearningExample } from '../classes';
import { LearninExampleAPI } from '../enums';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LearningExamplesService {
  constructor(private httpService: HttpService, private aiService: AiService, private userService: UserService) {}

  public async getLearningExamples() {
    const data = await this.httpService.getDataFromService(LearninExampleAPI.GetLearningExamples);
    return data;
  }

  public async getLearningExamplesRandom() {
    const langString = this.userService.getUserLangOptions();

    const data = await this.httpService.getDataFromService(`${LearninExampleAPI.GetLearningExamplesRandom}?${langString}`);
    return data;
  }

  public async getWordLearningExamples(word: string): Promise<any> {
    const langString = this.userService.getUserLangOptions();

    const data = await this.httpService.getDataFromService(`${LearninExampleAPI.GetWordLearningExample}/${word}?${langString}`);
    return data;
  }

  public async postLearningExample(word: string, learningExample: any) {
    const langString = this.userService.getUserLangOptions();

    const data = await this.httpService.postDataToService(`${LearninExampleAPI.PostLearningExample}/${word}?${langString}`, learningExample);
    return data;
  }

  public async autogenerateLearningExample(word, learningExample: LearningExample) {
    const langString = this.userService.getUserLangOptions();

    return await this.aiService.autocompleteLearningExample(word, learningExample);
  }

  public async deleteLearningExample(word: string, id: string) {
    const lang = this.userService.getUserSnapshot().settings.targetLanguage;

    return await this.httpService.deleteDataFromService(`${LearninExampleAPI.DeleteLearningExample}/${word}/${id}?target=${lang}`);
  }

  public async generateVoiceForLearningExample(word: string, id: string): Promise<string> {
    const langString = this.userService.getUserLangOptions();

    return await this.httpService.getDataFromService(`api/learningExamples/generateVoice/${word}/${id}?${langString}`, 'python');
  }
}
